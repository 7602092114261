<template>
	<div :id="`qmn${props.config.id}`" class="w-full flex justify-center">
		<ins class="asm_async_creative"
			 data-asm-cdn="cdn.f11-ads.com"
			 data-asm-host="ads.qualitymedianetwork.de"
			 data-asm-fetch-gdpr="1"
			 :data-asm-params="`pid=${props.config.id}`"
			 :style="insStyle"/>
	</div>
</template>

<!--suppress TypeScriptUnresolvedReference __tcfapi is injected via global qmn script -->
<script setup lang="ts">
import type {CmpLoadStatus, QmnAdConfig} from "../config/qmn";

const props = defineProps<{
	config: QmnAdConfig,
}>();

const insStyle = computed(() => {
	return {
		display: 'inline-block',
		width: `${props.config.width}px`,
		height: `${props.config.height}px`,
		textDecoration: 'none'
	};
});

function loadAd(cmpLoadStatus: CmpLoadStatus, success: boolean)  {
	if (!success || !cmpLoadStatus.gdprApplies) {
		return;
	}
	if (cmpLoadStatus.eventStatus === 'tcloaded' || cmpLoadStatus.eventStatus === 'useractioncomplete') {
		// If the ads were already loaded, we refresh the ads
		if (window.qmnAdsLoaded) {
			refreshAd();
			return;
		}
		window.qmnAdsLoaded = true;

		const qmnScript = document.createElement("script");
		qmnScript.src = "https://cdn.f11-ads.com/adasync.min.js";
		qmnScript.async;
		qmnScript.type = "text/javascript";
		document.body.appendChild(qmnScript);
	}
}

function refreshAd() {
	if (window.asm_async_data) {
		window.asm_async_data.initi();
	}
}

onMounted(() => {
	// If the ads were already loaded, we refresh the ads
	if (window.qmnAdsLoaded) {
		refreshAd();
		return;
	}

	if ( typeof __tcfapi === 'function' ) {
		__tcfapi('addEventListener', 2, loadAd);
	} else {
		let tries = 0;
		let interval = setInterval(() =>  {
			if (typeof __tcfapi === 'function') {
				clearInterval(interval);
				__tcfapi('addEventListener', 2, loadAd);
			} else {
				tries++;
				if (tries > 5) {
					clearInterval(interval);
				}
			}
		}, 3000);
	}
});

</script>